import React, {FunctionComponent, useState} from 'react'

import {Transition} from '@headlessui/react'

import OpenLawBanner from '../../assets/logos/open-law-banner.svg'
import JadeLogo      from '../../assets/logos/jade-logo-green.svg'
import VrLogo        from '../../assets/logos/vr-logo-black.png'

interface HeaderProps {
    showNav: boolean
    siteTitle: string
    scrolled: unknown
    navMenuItems: string[]
    textWhite: boolean
}

const Header: FunctionComponent<HeaderProps> = ({
                                                    showNav,
                                                    siteTitle,
                                                    scrolled,
                                                    navMenuItems = [],
                                                    textWhite = true
                                                }) => {
    const [activeMenu, setActiveMenu] = useState<null | 'projects' | 'about' | 'mobile'>(null)
    let headerClass = 'fixed w-full z-30 top-0 text-white'
    headerClass += scrolled ? ' bg-white shadow' : ''

    let navActionClass =
        'mx-auto lg:mx-0 hover:underline font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75'
    navActionClass += !textWhite || !scrolled ? ' bg-white text-gray-800' : ''
    navActionClass += textWhite || scrolled ? ' gradient text-white' : ''

    let navContentClass =
        'w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 text-black p-4 lg:p-0 z-20'
    navContentClass += !textWhite || !scrolled ? ' lg:bg-transparent bg-gray-100' : ''
    navContentClass += textWhite || scrolled ? ' bg-white' : ''

    let titleClass = 'toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl'
    titleClass += !textWhite || scrolled ? ' text-gray-800' : ''
    titleClass += textWhite || !scrolled ? ' text-white' : ''


    let projectsMenu = `${activeMenu === 'projects' ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1'} hidden md:block absolute inset-x-0 transform shadow-lg`

    {/* Solutions menu */
    }
    {/*
      'Solutions' flyout menu, show/hide based on flyout menu state.

      Entering: "transition ease-out duration-200"
        From: "opacity-0 -translate-y-1"
        To: "opacity-100 translate-y-0"
      Leaving: "transition ease-in duration-150"
        From: "opacity-100 translate-y-0"
        To: "opacity-0 -translate-y-1"
    */
    }

    const handleToggleMenu = (menu: 'projects' | 'about' | 'mobile') => menu !== activeMenu ? setActiveMenu(menu) : null

    return (
        <div onMouseLeave={() => setActiveMenu(null)} className="relative bg-white">
            <div className="relative z-50 shadow">
                <div
                    className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
                    <div>
                        <a href="/" className="flex">
                            <span className="sr-only">Open Law</span>
                            <img className="h-12 w-auto sm:h-20"
                                 src={OpenLawBanner} alt=""/>
                        </a>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <button onClick={() => handleToggleMenu('mobile')} type="button"
                                className="rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                            <span className="sr-only">Open menu</span>
                            {/* Heroicon name: menu */}
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        </button>
                    </div>
                    <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                        <nav className="flex space-x-10">
                            <div className="relative">
                                {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
                                <a href="/about" type="button"
                                   className="group rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    <span>About</span>
                                </a>
                            </div>
                            <div className="relative">
                                {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
                                <button type="button"
                                        onClick={() => handleToggleMenu('projects')}
                                        className={`${activeMenu === 'about' ? 'text-black' : 'text-gray-500'} group rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}>
                                    <span>Projects</span>
                                    {/*
                              Heroicon name: chevron-down

                              Item active: "text-gray-600", Item inactive: "text-gray-400"
                            */}
                                    <svg className="ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-500"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                         aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="relative">
                                {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
                                <a href="/training" type="button"
                                   className="group rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    <span>Webinars</span>
                                </a>
                            </div>
                            <div className="relative">
                                {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
                                <a href="/events" type="button"
                                   className="group rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    <span>Events & Training</span>
                                </a>
                            </div>
                            {/*
                            <a href="/blog" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                Blog
                            </a>
*/}
                            {/*                            <a href="/labs" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                Labs
                            </a>*/}
                        </nav>
                        <div className="flex items-center md:ml-12">

                            {/*
                            <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                Join Mailing List
                            </a>
*/}

                            <a href="/access"
                               className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700">
                                Upgrade your JADE Account
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Solutions menu */}
            {/*
      'Solutions' flyout menu, show/hide based on flyout menu state.

      Entering: "transition ease-out duration-200"
        From: "opacity-0 -translate-y-1"
        To: "opacity-100 translate-y-0"
      Leaving: "transition ease-in duration-150"
        From: "opacity-100 translate-y-0"
        To: "opacity-0 -translate-y-1"
    */}
            <Transition
                show={activeMenu === 'projects'}
                enter={'transition ease-out duration-200'}
                enterFrom={'opacity-0 -translate-y-1'}
                enterTo={'opacity-100 translate-y-0'}
                leave={'transition ease-in duration-150'}
                leaveFrom={'opacity-100 translate-y-0'}
                leaveTo={'opacity-0 -translate-y-0'}
            >
                <div className="hidden z-50 md:block absolute inset-x-0 transform shadow-lg">
                    <div className="bg-white">
                        <div
                            className="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                            <a href="/projects/jade"
                               className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                                <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
                                  <span
                                      className="inline-flex items-center justify-center h-12 w-12 rounded-md text-white sm:h-14 sm:w-14">
                                    {/* Heroicon name: chart-bar */}
                                      <img className="h-14 w-14" src={JadeLogo}
                                           alt={'Judgments and Decisions Enhanced (JADE) Logo'}/>
                                  </span>
                                    </div>
                                    <div
                                        className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                        <div>
                                            <p className="text-base font-medium text-gray-900">
                                                Judgments and Decisions Enhanced (JADE)
                                            </p>
                                            <p className="mt-1 text-sm text-gray-500">
                                                JADE is the best solution for finding Australian case law and
                                                legislation.
                                            </p>
                                        </div>
                                        <p className="mt-2 text-sm font-medium text-blue-600 lg:mt-4">Learn more <span
                                            aria-hidden="true">&rarr;</span></p>
                                    </div>
                                </div>
                            </a>

                            <a href="/projects/victorian-reports"
                               className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                                <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
              <span
                  className="inline-flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12">
                {/* Heroicon name: cursor-click */}
                  <img className="w-15" src={VrLogo} alt={'Victorian Reports Logo'}/>
              </span>
                                    </div>
                                    <div
                                        className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                        <div>
                                            <p className="text-base font-medium text-gray-900">
                                                Victorian Reports
                                            </p>
                                            <p className="mt-1 text-sm text-gray-500">
                                                Publishing {new Date().getFullYear() - 1875} years of Victorian
                                                jurisprudence in print and online.
                                            </p>
                                        </div>
                                        <p className="mt-2 text-sm font-medium text-blue-600 lg:mt-4">Learn more <span
                                            aria-hidden="true">&rarr;</span></p>
                                    </div>
                                </div>
                            </a>

                            <a href="/projects/publication-of-secondary-law"
                               className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                                <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
              <span
                  className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-gray-500 text-white sm:h-12 sm:w-12">
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/>
                </svg>
              </span>
                                    </div>
                                    <div
                                        className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                        <div>
                                            <p className="text-base font-medium text-gray-900">
                                                Publication of Secondary Law
                                            </p>
                                            <p className="mt-1 text-sm text-gray-500">
                                                The Open Law Ecosystem for the publication of secondary law
                                                connects sources of legal information together.
                                            </p>
                                        </div>
                                        <p className="mt-2 text-sm font-medium text-blue-600 lg:mt-4">Learn more <span
                                            aria-hidden="true">&rarr;</span></p>
                                    </div>
                                </div>
                            </a>

                            <a href="/projects/one-to-100"
                               className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                                <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
              <span
                  className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-red-700 text-white sm:h-12 sm:w-12">
                  100
                  {/*                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>*/}
              </span>
                                    </div>
                                    <div
                                        className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                        <div>
                                            <p className="text-base font-medium text-gray-900">
                                                One to 100
                                            </p>
                                            <p className="mt-1 text-sm text-gray-500">
                                                A crowd-funded initiative to provide open access to the Commonwealth Law
                                                Reports.
                                            </p>
                                        </div>
                                        <p className="mt-2 text-sm font-medium text-blue-600 lg:mt-4">Learn more <span
                                            aria-hidden="true">&rarr;</span></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="bg-gray-300">
                        <div
                            className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                            <div className="flow-root">
                                <a href="#"
                                   className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                    {/* Heroicon name: play */}
                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                         xmlns="http://www.w3.org/2000/svg"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/>
                                    </svg>
                                    <a href={'/solutions'}>
                                        <span className="ml-3">Publishing Solutions</span>
                                    </a>
                                </a>
                            </div>

                            <div className="flow-root">
                                <a href="/projects"
                                   className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                    {/* Heroicon name: check-circle */}
                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                         xmlns="http://www.w3.org/2000/svg"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 2a1 1 0 011 1v1.323l3.954 1.582 1.599-.8a1 1 0 01.894 1.79l-1.233.616 1.738 5.42a1 1 0 01-.285 1.05A3.989 3.989 0 0115 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.715-5.349L11 6.477V16h2a1 1 0 110 2H7a1 1 0 110-2h2V6.477L6.237 7.582l1.715 5.349a1 1 0 01-.285 1.05A3.989 3.989 0 015 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.738-5.42-1.233-.617a1 1 0 01.894-1.788l1.599.799L9 4.323V3a1 1 0 011-1zm-5 8.274l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L5 10.274zm10 0l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L15 10.274z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    <a href={'/projects'}>
                                        <span className="ml-3">All Projects</span>
                                    </a>
                                </a>
                            </div>

                            <div className="flow-root">
                                <a href="/contact"
                                   className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                    {/* Heroicon name: phone */}
                                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                         xmlns="http://www.w3.org/2000/svg"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                    </svg>
                                    <span className="ml-3">Contact Us</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            {/*
      Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-200 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    */}
            <Transition
                show={activeMenu === 'mobile'}
                enter={'ease-out duration-200'}
                enterFrom={'opacity-0 scale-95'}
                enterTo={'opacity-100 scale-100'}
                leave={'ease-in duration-100'}
                leaveFrom={'opacity-100 scale-100'}
                leaveTo={'opacity-0 scale-95'}>
                <div className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div
                        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5 sm:pb-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img className="h-8 w-auto sm:h-10"
                                         src={OpenLawBanner} alt=""/>
                                </div>
                                <div className="-mr-2">
                                    {/*
                                    <button onClick={() => handleToggleMenu('mobile')} type="button"
                                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                        <span className="sr-only">Close menu</span>

                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                */}
                                </div>
                            </div>
                            <div className="mt-6 sm:mt-8">
                                <nav>
                                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                                        <a href="/projects"
                                           className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                                            <div
                                                className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill=""
                                                     viewBox="0 0 24 24" stroke="#FFF" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2"
                                                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                                                </svg>
                                            </div>
                                            <div className="ml-4 text-base font-medium text-gray-900">
                                                Projects
                                            </div>
                                        </a>

                                        <a href="/access"
                                           className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                                            <div
                                                className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                                                {/* Heroicon name: cursor-click */}
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          strokeWidth="2"
                                                          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"/>
                                                </svg>
                                            </div>
                                            <div className="ml-4 text-base font-medium text-gray-900">
                                                Access Open Law Services
                                            </div>
                                        </a>

                                        <a href="/training"
                                           className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                                            <div
                                                className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                                                {/* Heroicon name: shield-check */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              stroke-width="2"
                                                              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"/>
                                                    </svg>
                                            </div>
                                            <div className="ml-4 text-base font-medium text-gray-900">
                                                Webinars
                                            </div>
                                        </a>

                                        <a href="/events"
                                           className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                                            <div
                                                className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                                                {/* Heroicon name: view-grid */}
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          strokeWidth="2"
                                                          d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
                                                </svg>
                                            </div>

                                            <div className="ml-4 text-base font-medium text-gray-900">
                                                Events & Training
                                            </div>

                                        </a>
                                    </div>
                                    {/*
                                    <div className="mt-8 text-base">
                                        <a href="#" className="font-medium text-blue-600 hover:text-blue-500"> View
                                            all products
                                            <span aria-hidden="true">&rarr;</span></a>
                                    </div>
*/}
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5">
                            <div className="grid grid-cols-2 gap-4">
                                {/*<a href="/history"*/}
                                {/*   className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">*/}
                                {/*    History*/}
                                {/*</a>*/}

                                {/*<a href="/careers"*/}
                                {/*   className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">*/}
                                {/*    Careers*/}
                                {/*</a>*/}
                                {/*

                                <a href="/guides"
                                   className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                    Guides
                                </a>

                                <a href="/resources"
                                   className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                    Resources
                                </a>

*/}
                            </div>
                            <div className="mt-6">
                                {/*
                                <a href="/mailing-list"
                                   className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700">
                                    Join Mailing List
                                </a>
*/}
                                {/*
                                <p className="mt-6 text-center text-base font-medium text-gray-500">
                                    Existing customer?
                                    <a href="#" className="text-blue-600 hover:text-blue-500">
                                        Sign in
                                    </a>
                                </p>
*/}
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    )
}

export default Header
